<template>
	<div id="assigned-job-test">
    <v-card>
		<v-card-text>
			<v-row no-gutters>
				<!-- Test header: job name & timer -->
				<v-col v-if="!noHeader" :cols="12">
					<v-row no-gutters>
						<v-col>
							<router-link 
								to="#" 
								class="untouchable fs-26 lh-32 fw-600"
							>
								{{ model.job_name }}
							</router-link>
							<div class="fs-12 lh-19" v-if="_.has(model, 'teacher_name')">Автор {{ model.teacher_name }}</div>
						</v-col>
						<v-col sm="auto">
							<timer 
								:value="time_remain" 
								chip 
								icon 
								color="green darken-0" 
							/>
						</v-col>

						<v-col sm="auto">
							<v-btn 
								:elevation="0" 
								color="green darken-0"
								class="confirm-button white--text ml-2"
								rounded
								@click.prevent.stop="confirmComplete"
							>
								<v-icon :size="16" class="mr-1">mdi-check-all</v-icon>
								Сдать работу
							</v-btn>
						</v-col>
					</v-row>
				</v-col>				

				<v-col v-if="!noHeader" :cols="12">
					<v-divider class="my-2" />
				</v-col>

				<v-col :cols="12">
					<assigned-job-test-pagination :model="model" @select="to" />
				</v-col>

				<v-col :cols="12">
					<v-divider class="my-1" />
				</v-col>

				<!-- Test question description -->
				<v-col :cols="12">
					<div class="fs-14 lh-19 fw-700 black--text mb-8">Вопрос {{ _.get(activeTask, 'number', 0) }} из {{ totalTaskCount }}</div>
					<h2 class="fs-22 lh-32 fw-700 black--text">{{ _.get(activeTask, 'title', '') }}</h2>
				</v-col>

				<!-- Test question interactive component -->
				<v-col :cols="12" class="my-7" :class="{'untouchable opacity-25': isWaitingForSave}" :key="activeTask.id" :data-task-id="activeTask.id">
                    <examen-vpr-template ref="task" :model="activeTask" :mode="mode" @update="answer" :data-task-id="activeTask.id" />
				</v-col>

				<!-- Test actions -->
				<v-col :cols="12">
					<v-row no-gutters align="stretch">
						<v-col>
							<v-btn text :disabled="!allowPrev" @click.prevent.stop="prev">Назад</v-btn>
						</v-col>
						<v-spacer />
						<v-col>
							<div class="text-right">
								<v-btn 
									:elevation="0" 
									color="green darken-0"
									class="white--text mb-4"
									:disabled="!allowNext"
									@click.prevent.stop="next"
								>
									Следующий вопрос
									<v-icon>mdi-arrow-right</v-icon>
								</v-btn>
							</div>
							<!-- Answer confirm -->
							<v-dialog
								v-model="dialogs.confirm"
								transition="dialog-bottom-transition"
								persistent
								max-width="480"
							>
								<v-card id="assigned-job-test__dialog">
						        <v-card-title>
						          <h2>Сдать работу?</h2>
						        </v-card-title>
						        <v-card-text>
						        	<h3>После подтверждения выполнение работы завершается и невозможно внести изменения в ответы.</h3>
						        </v-card-text>
						        <v-card-actions>
						          <v-btn
						          	text
									:disabled="waitingComplete"
						            @click.prevent.stop="dialogs.confirm = false"
						          >
						            Нет
						          </v-btn>
						          <v-spacer></v-spacer>
						          <v-btn
						            color="green darken-0"
						            class="white--text"
									:loading="waitingComplete"
						            @click.prevent.stop="confirmComplete"
						          >
						            Да
						          </v-btn>
						        </v-card-actions>
						      </v-card>
							</v-dialog>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
	<v-overlay v-if="waitingComplete && complete_reason === 'timeout'" :value="true">
		<v-progress-circular
			indeterminate
			:size="70"
        	:width="7"
			color="purple darken-0"
		></v-progress-circular>
	</v-overlay>
	</div>
</template>
<script>
import Timer from '@/components/ui/Timer.vue'
import ExamenVprTemplate from '@/components/ui/ExamenVprTemplate.vue'
import AssignedJobTestPagination from '@/components/assigned_job/AssignedJobTestPagination.vue'
export default {
    name: 'AssignedJobTest',
    components: { Timer, ExamenVprTemplate, AssignedJobTestPagination },
    props: {
    	model: Object,
		demo: Boolean,
		noHeader: Boolean,
		mode: {
			type: String,
			default: 'control'
		}
    },
    data() {
    	return {
    		refresh: 1,
    		timer: null,
    		timer_task: null,
    		time_remain: 0,
			waitingResolve: null,
			waitingComplete: false,
    		dialogs: {
    			confirm: false
    		},
			complete_reason: null
    	}
    },
    computed: {
    	will_ended_at() {
    		return this.model.result.started_at + this.model.time;
    	},
    	totalTaskCount() {
    		return _.size(this.model.job_tasks);
    	},
    	activeTask() {
    		return _.find(this.model.job_tasks, {active: true});
    	},
    	allowPrev() {
    		return this.activeTask.number > 1;
    	},
    	allowNext() {
    		return this.activeTask.number < this.totalTaskCount;
    	},
		isWaitingForSave() {
			return !_.isNil(this.waitingResolve);
		}
    },
    methods: {
    	async timerHandler() {
    		const timestamp_end = this.will_ended_at;
    		const timestamp_current = _.parseInt(this.$moment().format('X'));
    		this.time_remain = Math.max(0, timestamp_end - timestamp_current);
    		if(this.time_remain <= 0)
    		{
				this.waitingComplete = true;
				this.complete_reason = 'timeout';
				await this.$nextTick();
    			this.$emit('complete', 'timeout');
    			clearInterval(this.timer);
    		}
    	},
    	async to(number) {
			await this.saveActiveTaskAnswer();

    		const target = _.find(this.model.job_tasks, {number: _.parseInt(number)});
			this.activeTask.active = false;
			target.active = true;
    	},
    	async inc(dir) {
			await this.saveActiveTaskAnswer();

    		const neighbour = _.find(this.model.job_tasks, {number: this.activeTask.number + 1 * dir});
			this.activeTask.active = false;
			neighbour.active = true;
    	},
    	prev() {
    		if(this.allowPrev)
    			this.inc(-1);

    		return ;
    	},
    	next() {
    		if(this.allowNext)
    			this.inc(1);		

    		return ;
    	},
        async confirmComplete() {
            // Confirm
            if(this.dialogs.confirm === false)
            {
                this.dialogs.confirm = true;
                return ;
            }
			// Save active task
			await this.saveActiveTaskAnswer();
			// Complete test
			this.waitingComplete = true;
			this.complete_reason = 'complete';
			await this.$nextTick();
            this.$emit('complete', 'complete');
			clearInterval(this.timer);
        },
		async saveActiveTaskAnswer() {
			// Disable save answers before previous saved
			if(this.isWaitingForSave)
				return ;
			// Create waiting promise
			const waiting = new Promise((resolve) => {
				this.waitingResolve = resolve;
			});
			// Create answer request
			this.$refs.task.updateRequest();
			// Wait until result_of_task saving
			await waiting;
			// Clear 
			this.waitingResolve = null;

			return ;
		},
    	async answer(user_answer) {
            // Save answer
			const result_of_task = {
				result_id: _.get(this, 'model.result.id', 0),
				assigned_job_id: _.get(this, 'model.id', 0),
				task_id: _.get(this, 'activeTask.id', 0),
				answer: user_answer,
				time: _.get(this, 'activeTask.result_of_task.time', 0)
			}
			if(this.demo === true)
			{ 
				_.assign(this.activeTask.result_of_task, {
					id: _.now(),
					score: _.get(JSON.parse(user_answer), 'statistic.totalScore', 0),
					maxScore: _.get(JSON.parse(user_answer), 'statistic.maxScore', 0),
					...result_of_task
				});
			}
			else
			{
				const { success, data } = await this.$store.dispatch('result_of_task/upsert', {
					...this.activeTask.result_of_task,
					...result_of_task
				})
				if(success)
					_.assign(this.activeTask.result_of_task, data);
			}

			// Refresh template
			this.refresh++;
			
			// Resolve waiting promise
			if(!_.isNil(this.waitingResolve))
				this.waitingResolve(true);

    		return ;
    	}
    },
    created() {
    	// Timer init
    	this.timerHandler();
    	this.timer = setInterval(this.timerHandler, 1000);

    	// Task time timer
    	this.timer_task = setInterval(() => {
    		const bHasActiveTask = _.get(this, 'activeTask', false);
    		if(bHasActiveTask)
    			this.activeTask.result_of_task.time++;
    	}, 1000);
    	
    },
    beforeDestroy() {
    	clearInterval(this.timer);
    	clearInterval(this.timer_task);
    }
}
</script>
<style lang="scss" scoped>
	.confirm-button{
		height: 32px!important;
	}
</style>