<template>
	<v-container id="demo-detail-page" fluid>
        <v-row>
			<v-col :cols="12">
				<router-link to="/" class="d-flex flex-row flex-nowrap justify-start align-center">
					<v-icon>mdi-chevron-left</v-icon>
					Вернуться на главную
				</router-link>
			</v-col>

            <v-col v-if="ready" cols="12">
                <assigned-job-test
                    v-if="completed !== true"
                    :model="model" 
                    demo
                    @complete="complete" 
                    class="elevation-0"
                />
                <div v-else>
                    <v-card class="elevation-0">
		                <v-card-text>
                            <v-row no-gutters>
                                <v-col :cols="12">
                                    <v-row no-gutters>
                                        <v-col>
                                            <router-link 
                                                to="#" 
                                                class="untouchable fs-26 lh-32 fw-600"
                                            >
                                                {{ model.job_name }}
                                            </router-link>
                                        </v-col>
                                    </v-row>
                                </v-col>				

                                <v-col :cols="12">
                                    <v-divider class="my-2" />
                                </v-col>

                                <v-col :cols="12">
                                    <h1>Ваш результат: {{ stats.resultPercent }}%</h1>
                                    <h2>Вы набрали {{ stats.totalScore }} из {{ stats.maxScore }} баллов.</h2>
                                    <v-divider class="my-4" />
                                    <div class="my-4 d-flex flex-row justify-center align-center">
                                        <v-btn 
                                            :elevation="0" 
                                            to="/login" 
                                            color="green darken-0"
                                            class="mr-1"
                                        >
                                            Авторизация
                                        </v-btn>
                                        <v-btn 
                                            :elevation="0" 
                                            to="/#demo" 
                                            color="green darken-0"
                                            class="ml-1"
                                        >
                                            К списку демо-работ
                                        </v-btn>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import AssignedJobTest from '@/components/assigned_job/AssignedJobTest.vue'
export default {
    name: 'Demo',
    components: { AssignedJobTest },
    data() {
    	return {
            ready: false,
            completed: false,
            model: null,
            stats: null
        };
    },
    computed: {
        subject() {
            return _.get(this, '$route.params.subject', 0);
        },
        grade() {
            return _.get(this, '$route.params.grade', 0);
        },
        is_final() {
            return _.get(this, '$route.params.is_final', 0);
        }
    },
    methods: {
        async complete() {
            const maxScore = _.sumBy(this.model.job_tasks, 'result_of_task.maxScore');
            const totalScore = _.sumBy(this.model.job_tasks, 'result_of_task.score');
            this.stats = {
                maxScore: maxScore,
                totalScore: totalScore,
                resultPercent: maxScore > 0 ? Math.round(totalScore / maxScore * 100) : 0
            }
            this.completed = true;
        }
    },
    async created() {
        if(_.size(this.$store.state.job.lists.demo.items) <= 0)
            await this.$store.dispatch('job/fetchDemo');

        const job = this.$store.getters['job/findDemoBySubjectAndGrade'](this.subject, this.grade, this.is_final);
        const tasks = await this.$store.dispatch('job/fetchDemoTasks', {
            filter: {
                preset: 'tasks_of_job',
                subject: job.subject,
                grade: job.grade,
                option: 1,
                is_final: job.is_final
            },
            pagination: 0
        });

        this.model = {
            ...job,
            job_tasks: _.map(_.get(tasks, 'data.items', []), (o,k) => {
                o['active'] = k <= 0 ? true : false;
                o['number'] = k + 1;
                o['result_of_task'] = {
                    time: 0
                };
                return o;
            }),
            result: {
                started_at: _.parseInt(this.$moment().format('X'))
            }
        }

        this.ready = true;
    }
}
</script>
<style lang="scss">
#demo-detail-page,
#assigned-job-test,
#assigned-job-test__dialog{
    // Headers
    h1, .h1 {
        font-style: normal;
        font-weight: 600;
        font-size: 1.625rem;
        line-height: 2rem;
    }
    h2, .h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 1.125rem;
        line-height: 2rem;
    }
    h3, .h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.25rem;
    }
}
</style>