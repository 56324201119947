<template>
    <component :is="wrapper" :color="color">
        <span class="text-no-wrap white--text">
            <v-icon v-if="icon" :color="iconColor" :size="16">mdi-timer-outline</v-icon>
            <span class="timer__value d-inline-block">{{ formattedValue }}</span>
        </span>
    </component>
</template>
<script>
import TimeHelper from '@/helpers/TimeHelper'
import { VChip } from 'vuetify/lib'
export default {
    name: 'Timer',
    props: {
        value: [Number, String],
        color: String,
        iconColor: {
            type: String,
            default: '#FFFFFF'
        },
        chip: Boolean,
        icon: Boolean,
    },
    data() {
    	return {}
    },
    computed: {
        wrapper() {
            return this.chip === true ? VChip : 'div';
        },
        formattedValue() {
            return _.isNumber(this.value) ? TimeHelper.secondsToTime(this.value) : this.value;
        }
    }
}
</script>
<style lang="scss" scoped>
.timer__value{
    width: 65px;
    text-align: right;
}
</style>