<template>
	<div class="assigned-job-test-pagination py-2">
	    <div class="d-flex flex-row flex-nowrap justify-center align-center mb-4">
	    	<a 
	    		href="#" 
	    		v-for="(item,i) in model.job_tasks" 
	    		:key="i"
	    		class="assigned-job-test-pagination__item mx-2"
	    		:class="{answered: isAnswered(item), current: item.number == activeNumber}"
	    		@click.prevent.stop="select(item)"
	    	></a>
	    </div>
	    <div class="d-flex flex-row flex-nowrap justify-center align-center">
	    	<span>Вопрос {{ activeNumber }} из {{ total }}</span>
	    </div>
	</div>
</template>
<script>
export default {
    name: 'AssignedJobTestPagination',
    props: {
    	model: Object
    },
    data() {
    	return {}
    },
    computed: {
    	total() {
    		return _.size(this.model.job_tasks);
    	},
    	activeNumber() {
    		return _.get(_.find(this.model.job_tasks, {active: true}), 'number', 1);
    	}
    },
    methods: {
    	isAnswered(item) {
    		return _.has(item.result_of_task, 'id');
    	},
    	select(item) {
    		this.$emit('select', item.number);
    	}
    },
}
</script>
<style lang="scss" scoped>
	.assigned-job-test-pagination{
		.assigned-job-test-pagination__item{
			height: .375rem;
			background: rgba(0, 0, 0, 0.12);
			border-radius: 100px;
			min-width: 2rem;
			flex: 0 0 2rem;

			&.answered{
				background: #0D61FF;
			}

			&.current{
				position: relative;
				&::before{
					content: "";
					position: absolute;
					width: .375rem;
					height: .375rem; 
					background: #0D61FF;
					border-radius: 100px;
				}
			}
		}
	}
</style>	